<template>
  <section>
    <div class="content-header">
      <h2>“How Your Unique Story Can Get You Hired” </h2>
    </div>
    <div class="content-wrapper">
      <p>When searching for a job, students may feel like they're not good enough or qualified enough to get it. But Aimee Eubanks says, “I'm here to tell you that your self-doubt about your experiences can be the key to driving your career success.”</p>
      <p>She adds that, “What I've realized is that these experiences that seem like a liability are actually your differentiating strength. The secret is to transform how you perceive your own story. Even if you've been on an untraditional path, you've accrued some skills over time that are really valuable in the workforce. Your task is to identify those experiences and trumpet them, because it's likely that story, that is your ticket to a great job.”</p>
      <p>Share Aimee Eubanks’ <a href="https://www.ted.com/talks/aimee_eubanks_davis_how_your_unique_story_can_get_you_hired" target="_blank">“How your unique story can get you hired”</a> Ted Talk to help students take their doubts and struggles and turn them into strengths.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '09',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
